.dirSelection {
  grid-area: dirSelection;

  .header {
    display: flex;
    gap: 0.5em;
    margin-top: 0.7em;
    justify-content: space-between;
    flex-wrap: wrap;

    .actions {
      display: flex;
      gap: 1em;
    }
  }

  .dirs-wrapper {
    border-radius: 10px;
    overflow: hidden;
  }

  .dirs {
    position: relative;
    overflow-y: auto;
    height: 224px;
    border-radius: inherit;
    background: var(--modalSecondaryColor);
    scrollbar-width: thin;

    .vertical-err {
      position: absolute;
      left: 50%;
      transform: translate(-50%);

      p {
        color: var(--primaryTextColor);
      }
    }

    .dir {
      display: grid;
      grid-template-columns: 20px 1fr 5px;
      gap: 0.9em;
      padding: 0 0.9em;
      align-items: center;
      cursor: pointer;
      min-height: 32px;
      color: var(--primaryTextColor);

      &:hover {
        background: var(--modalTertiaryColor);
      }

      &.selected-true .selectBox {
        background: var(--accentColor);

        svg {
          opacity: 1;
        }
      }

      .label {
        padding: 0.5em 0;
        width: 100%;
        display: flex;

        // folder icon
        svg {
          margin-right: 10px;
          fill: var(--primaryTextColor);
        }

        // path name
        p {
          display: flex;
          width: 100%;
          margin: 0;
          user-select: none;
          overflow-wrap: break-word;
          overflow: hidden;
          word-break: break-all;

          .selectedInsideCount {
            color: var(--tertiaryTextColor);
            margin-left: auto;
            align-self: center;
            font-size: 0.8em;
          }
        }
      }

      .selectBox {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 20px;
        border-radius: 5px;
        border: solid 2px var(--accentColor);
        transition: background 150ms ease-in-out;
        margin-right: 0.5em;
        opacity: 1;

        svg {
          fill: var(--primaryTextColor);
          font-size: 0.6em;
          overflow: unset;
          opacity: 0;
          transition: opacity 150ms ease-in-out;
        }
      }

      .chevron-hint {
        svg {
          font-size: 0.5em;
          fill: var(--tertiaryTextColor);
        }
      }
    }
  }

  .controls {
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    gap: 0.4em;

    .current-folder-label {
      display: flex;
      margin-right: 0.6em;
      align-items: center;

      .current-label {
        color: #e4e4e4;
        font-weight: bold;
        min-width: fit-content;
      }
      .current {
        margin-left: 0.5em;
        font-family: "Roboto Condensed Regular", Arial;
        color: #9e9e9e;
        word-break: break-word;
      }
    }
  }

  .folders-selected-cnt {
    display: flex;

    & > h4:nth-of-type(1) {
      color: #9e9e9e;
    }

    & > h4:nth-of-type(2) {
      color: #eaeaea;
      padding-left: 0.4em;
    }
  }
}
