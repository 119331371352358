.preferencesAppearance {
  display: grid;
  gap: 1em;

  .themes {
    display: grid;
    gap: 1em;
  }

  .themeContainer {
    .theme {
      border-radius: 10px;
      overflow: hidden;
      border: solid 3px transparent;
      transition: border 200ms ease-in-out;

      &:not(.active) {
        cursor: pointer;
      }

      &.active {
        border: solid 3px var(--primaryTextColor);
        opacity: 1;
      }

      svg {
        display: block;
        width: 100%;
        fill: #000;
        height: 100%;
      }
    }

    p {
      margin-top: 0.5em;
    }
  }
}

@media only screen and (min-width: 480px) {
  .preferencesAppearance .themes {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}
