.banner {
  min-height: 50vmin;
  width: 100%;
  overflow: hidden;
  gap: 30px;
  padding: 30px;
  grid-template-rows: auto 1fr auto;
  align-items: center;
  display: grid;
  grid-template-areas:
    "genres"
    "info"
    "progressbar";
  transition: padding 0.3s ease-in-out;
  border-bottom: 4px solid var(--bannerBottomBorderColor);

  .placeholder {
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
    text-align: center;
    background: var(--bannerPrimaryColor);

    h2 {
      font-family: "Roboto Bold", Arial;
      animation: appear 500ms ease-in-out;
    }

    p {
      margin: 1em 0;
      max-width: 45ch;
      line-height: 25px;
      animation: appear 500ms ease-in-out;
    }

    button {
      font-size: 16px;
      transition: filter 0.2s ease-in-out;
      filter: saturate(0.7);
      font-family: "Roboto Bold", Arial;
      border-radius: 10px;
      background: var(--accentColor);
      color: #2a2a2a;
      padding: 0.5em 0.8em;
      animation: appear 500ms ease-in-out;

      &:focus,
      &:hover {
        filter: saturate(1);
      }
    }
  }

  .imageWrapper {
    z-index: -1;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    overflow: hidden;

    .placeholder {
      background: var(--bannerPrimaryColor);
    }

    .imageLoad {
      height: 100%;
      animation: onHideBannerImage 0s ease-in-out forwards;

      &.show-true {
        animation: onActiveBannerImage 400ms ease-in-out forwards;
      }

      &.show-false {
        animation: onHideBannerImage 300ms ease-in-out forwards;
      }

      img {
        mask-image: linear-gradient(to bottom, #000 80%, transparent 100%);
        transition: object-position 0.3s ease-in-out;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      // overlay shadow
      &::after {
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: 100%;
        background: var(--bannerOverlayShadow);
        transition: background 0.2s ease-in-out;
      }
    }
  }

  // year + genre
  .extras {
    grid-area: genres;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    a {
      color: var(--bannerPrimaryTextColor);
    }

    // separator
    svg {
      display: none;
      margin: 0 10px;
      font-size: 0.4em;
      fill: var(--accentColor);
    }

    // not year
    a:not(:first-child) {
      display: none;
      margin: 0 15px 0 0;
      transition: color 0.2s ease-in-out;

      &:hover {
        color: var(--bannerTertiaryTextColor);
      }
    }
  }

  .info {
    max-width: 60ch;
    line-height: 1.5em;
    grid-area: info;
    color: var(--bannerPrimaryTextColor);

    h1 {
      font-size: 2em;
      line-height: 1.3em;
      font-family: "Roboto Bold", Arial;
    }

    .description {
      margin: 1em 0;
      color: var(--bannerSecondaryTextColor);
    }
  }
}

/*
    * == KEYFRAMES ==
*/

@keyframes onActiveBannerImage {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes onHideBannerImage {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.05);
  }
}

@keyframes onHideBanner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes onActiveBanner {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*
    * == MEDIA QUERIES ==
*/

@media only screen and (min-width: 700px) {
  .banner .extras {
    svg {
      display: block;
    }
    // not year
    a:not(:first-child) {
      display: block;
    }
  }
}

@media only screen and (max-width: 2560px) and (max-height: 1440px) {
  .banner img {
    object-position: 30% 30%;
  }
}

@media only screen and (max-width: 720px) and (max-height: 1440px) {
  .banner img {
    object-position: 50%;
  }
}

@media only screen and (min-width: 1080px) {
  .banner {
    padding: 40px 90px 60px 90px;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "genres ."
      "info progressbar";

    .imageWrapper {
      &::after {
        background: #1a1a1abb;
      }
    }

    .extras {
      left: 90px;
    }
    .info {
      left: 90px;
      max-width: 450px;
    }
  }
}
