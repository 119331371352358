.ringLoad {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;

  &.small-true div {
    border: 0.2em dotted var(--accentColor);
  }

  div {
    display: block;
    position: absolute;
    width: inherit;
    height: inherit;
    border: 0.3em dotted var(--accentColor);
    border-radius: 50%;
    animation: ringLoad 1.5s cubic-bezier(0.68, -0.6, 0.32, 1.6) infinite;
    border-color: var(--accentColor);
  }
}

@keyframes ringLoad {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
