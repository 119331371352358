.nested-file-view {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 20px 0;
  width: 100%;
  overflow: auto;

  .nested-view-container {
    overflow: auto;
    padding: 0 40px;
    width: 100%;

    .nested-folder {
      display: flex;
      align-items: flex-start;
      width: 100%;
      flex-direction: column;

      &.with-border {
        border-bottom: 2px solid var(--quaternaryColor);
      }

      .folder-details {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        height: 40px;
        width: 100%;
        cursor: pointer;
        border-radius: 5px;
        opacity: 0.8;

        &:hover {
          opacity: 1;
        }

        svg {
          color: #fff;
        }

        &:hover,
        &.is-active {
          svg {
            color: #ffdebd;
          }
        }

        .folder-label {
          margin: auto 0;
          font-size: 14px;
          white-space: nowrap;
          overflow-wrap: anywhere;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .nested-children {
        width: 100%;
      }
    }
  }
}

.ReactCollapse--collapse {
  transition: height 200ms;
  max-width: 100%;
}

.file-view {
  display: flex;
  align-items: flex-start;
  gap: 18px;
  height: 40px;
  width: 100%;
  cursor: pointer;
  border-radius: 5px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  &.is-active {
    opacity: 1;
  }

  .select-box {
    display: flex;
    align-self: center;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
    border-radius: 4px;
    border: solid 2px var(--accentColor);
    transition: background 150ms ease-in-out;
    flex-shrink: 0;

    svg {
      margin: auto;
      font-size: 0.5em;
      overflow: unset;
      opacity: 0;
      transition: opacity 150ms ease-in-out;
    }

    &.is-active {
      background: var(--accentColor);

      svg {
        opacity: 1;
      }
    }
  }

  p {
    margin: auto 0;
    font-size: 14px;
    white-space: nowrap;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
