.search-box {
  position: relative;
  z-index: 1;
}

.search-box-wrapper {
  background: var(--quaternaryColor);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  height: 40px;

  input {
    background: var(--quaternaryColor);
    border: none;
    outline: none;
    padding: 10px 43px 10px 10px;
    color: var(--primaryTextColor);
    font-family: "Roboto Regular", Arial;
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    height: 100%;
    transition: padding 0.3s ease-in-out, background 100ms ease-in-out;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }

    &::placeholder {
      color: var(--secondaryTextColor);
    }
  }

  svg {
    overflow: visible;
    fill: var(--secondaryTextColor);
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 7px;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    font-size: 0.6em;
    background: var(--senaryColor);
    color: var(--primaryTextColor);
    outline: none;
    transform: translateY(-50%);
    transition: background 100ms ease-in-out;

    &:focus,
    &:hover {
      background: var(--septenaryColor);
    }
  }
}

@keyframes SidebarSearchForAppear {
  0% {
    opacity: 0;
    width: 0;
  }
  100% {
    opacity: 1;
    width: 100%;
  }
}

@keyframes PlaceholderAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*
    * == MEDIA QUERIES ==
*/

// @media only screen and (max-width: 1080px) {
//   .search-box-search-for {
//     // padding: 10px;
//     text-align: center;

//     p {
//       margin-bottom: 5px;
//       overflow: hidden;
//       white-space: unset;
//       text-overflow: ellipsis;
//     }

//     .horizontal-err {
//       margin: 0;
//     }
//   }
// }
