.mediaBannerImageWrapper {
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  z-index: -1;
  mask-image: linear-gradient(to bottom, #00000044, transparent);

  .imageLoad {
    height: 100%;
    width: 100%;
    animation: onHideImage 0s ease-in-out forwards;

    &.show-true {
      animation: onActiveImage 300ms ease-in-out forwards;
    }

    &.show-false {
      animation: onHideImage 300ms ease-in-out forwards;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      filter: blur(15px);
    }
  }
}

/*
  * == KEYFRAMES ==
*/

@keyframes onActiveImage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes onHideImage {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
