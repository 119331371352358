.toast {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  padding: 0.5em;
  border-radius: 10px;
  background: var(--quaternaryColor);
  color: var(--primaryTextColor);
  animation: appear 250ms ease-in-out forwards;
  font-family: "Roboto Bold";
  box-shadow: 0 0 0.25em #1a1a1a;

  button {
    width: 100%;
    border-radius: 5px;
    padding: 0.2em 0.5em;
    font-family: "Roboto Bold";
    font-size: 1em;
    background: #fff;

    &:hover {
      background: #eee;
    }
  }
}

@media only screen and (min-width: 350px) {
  .toast {
    flex-direction: row;
    gap: 0.5em;

    button {
      width: unset;
    }
  }
}
