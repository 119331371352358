.preferencesInvites {
  display: grid;
  gap: 1em;

  .tokensContainer {
    background: var(--tertiaryColor);
    border-radius: 10px;
    padding: 0.5em;
  }

  .heading {
    padding: 0.5em 0.5em 0 0.5em;
    gap: 1em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1em;
    font-family: "Roboto Bold";
  }

  .separator {
    margin: 0.5em 0;
  }

  .tokens {
    .token {
      user-select: none;
      padding: 0.3em 0.5em;
      border-radius: 10px;
      display: grid;
      gap: 1em;
      color: var(--primaryTextColor);
      align-items: center;
      grid-template-columns: 1fr 1fr 1fr 1em;
      word-break: break-all;

      &:hover {
        background: var(--secondaryColor);
        cursor: pointer;

        svg {
          opacity: 1;
        }
      }

      svg {
        cursor: pointer;
        opacity: 0;
        fill: var(--secondaryTextColor);

        &:hover {
          fill: #fff;
        }
      }

      button {
        font-size: 1em;
        background: transparent;
      }

      .createdAt {
        display: flex;
        gap: 1em;
      }
    }
  }

  .genTokenBtn {
    padding: 0.5em;
    font-size: 1em;
    border-radius: 10px;
    transition: filter 0.3s ease-in-out;
    background: var(--accentColor);
    filter: saturate(0.7);
    font-family: "Roboto Bold";
    outline: none;

    &:focus,
    &:hover {
      filter: saturate(1);
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &.critical {
      background: #ff6961;
    }

    &.important {
      background: transparent;
      color: var(--primaryTextColor);
      outline: solid 2px #ff6961;
    }
  }
}
