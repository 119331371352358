.selectUnmatchedMediaSearch {
  animation: appear 300ms ease-in-out;

  .desc {
    color: var(--secondaryTextColor);
    margin: 0.5em 0;
  }

  .searchField {
    display: flex;
    gap: 1em;

    button {
      background: var(--quaternaryColor);
      border-radius: 10px;
      padding: 1em 1.5em;

      &:hover {
        background: var(--tertiaryColor);
      }
    }
  }

  input {
    width: 100%;
    background: var(--quaternaryColor);
    color: var(--primaryTextColor);
    border-radius: 10px;
    padding: 1em;
    font-size: 1em;
    outline: none;
    border: none;
    font-family: "Roboto Regular", Arial;
  }

  .err {
    margin-top: 1em;
  }

  .tmdbResults {
    max-height: 25em;
    overflow: auto;
    padding-right: 15px;
    margin: 2em 0;
    display: grid;
    gap: 2em;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

    .resultCard {
      display: grid;
      gap: 0.5em;
      flex-direction: column;
      text-align: center;
      cursor: pointer;
      transition: opacity 200ms ease-in-out;

      &:hover {
        filter: brightness(1.2);
      }

      &.selected-false {
        opacity: 0.2;
      }

      .tmdbImageWrapper {
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        height: 200px;
        background: var(--secondaryColor);
        animation: onActiveImage 300ms ease-in-out forwards;
      }

      .imageLoad {
        width: 100%;
        height: 100%;
        animation: onHideImage 0s ease-in-out forwards;

        &.show-true {
          animation: onActiveImage 300ms ease-in-out forwards;
        }

        &.show-false {
          animation: onHideImage 300ms ease-in-out forwards;
        }
      }

      .selectedBox {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        transition: opacity 200ms ease-in-out;
        opacity: 0;

        &.selected-true {
          opacity: 1;
        }

        svg {
          background: var(--accentColor);
          fill: var(--accentTextColor);
          width: 32px;
          padding: 0.5em;
          border-radius: 10px;
          box-shadow: 0 0 10px var(--primaryColor);
        }
      }

      .placeholder {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 32px;
        }
      }

      img {
        border-radius: 10px;
        width: 100%;
        object-fit: cover;
      }

      p {
        padding: 0 0.5em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

@keyframes onHideImage {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
