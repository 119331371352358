.modalBox {
  box-shadow: #00000088 0 0 10px;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in-out;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: var(--modalPrimaryColor);
  outline: none;
  z-index: 1;
}

@media only screen and (min-width: 650px) {
  .modalBox {
    border: 1px solid #4a4a4a;
    max-width: 400px;
    height: auto;
    border-radius: 10px;
  }
}
