.modalSelectMediaFile {
  display: grid;
  gap: 1em;
  grid-template-rows: auto auto 1fr auto;
  color: var(--primaryTextColor);
  padding: 20px;

  h3 {
    font-family: "Roboto Condensed Regular", Arial;
  }

  .desc {
    margin-top: 0.5em;
    color: var(--secondaryTextColor);
  }

  .err {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    border-radius: 10px;
    background: var(--tertiaryColor);
  }

  .fileVersionsWrapper {
    border-radius: 10px;
    overflow: hidden;
  }

  .fileVersions {
    position: relative;
    overflow-y: auto;
    height: 150px;
    border-radius: inherit;
    background: var(--tertiaryColor);
    scrollbar-width: thin;

    .vertical-err {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
    }

    .fileVersion {
      display: flex;
      align-items: center;
      transition: background 0.1s ease-in-out;
      cursor: pointer;
      padding: 0.5em 10px;
      color: var(--primaryTextColor);

      &:hover {
        background: var(--quaternaryColor);
      }

      // folder icon
      svg {
        margin-right: 10px;
      }

      // path name
      p {
        margin: 0;
        user-select: none;
        overflow-wrap: break-word;
        overflow: hidden;
        word-break: break-all;
      }
    }
  }

  .options {
    display: flex;
    justify-content: flex-end;
  }
}

@media only screen and (min-width: 650px) {
  #modalSelectMediaFile {
    max-width: 600px;
  }
}
