.banner-wrapper {
  position: relative;
  z-index: 0;
  background: var(--bannerPrimaryColor);
  overflow: hidden;
  animation: bannerAppear 0.3s ease-in-out;
}

/*
    * == KEYFRAMES ==
*/

@keyframes bannerAppear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/*
    * == MEDIA QUERIES ==
*/

@media only screen and (min-width: 1080px) {
  .banner-wrapper {
    .crumbs {
      top: 40px;
      right: 90px;
    }
  }
}
