.videoMenus {
  display: flex;
  grid-area: menus;
  height: 210px;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 1080px;
  width: 100%;
  transition: opacity 200ms ease-in-out;

  &.false {
    opacity: 0;
    pointer-events: none;
  }

  .innerMenus {
    display: grid;

    p {
      font-family: "Roboto Regular";
      display: flex;
      padding: 0.5em;
      justify-content: space-between;
      border-radius: 5px;
      cursor: pointer;
      color: var(--secondaryTextColor);

      svg {
        width: 0.5em;
      }

      &:hover {
        background: var(--tertiaryColor);
      }
    }
  }

  .innerMenu {
    overflow: hidden;
  }

  .menu {
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    background: var(--primaryColor);
    width: 50%;
    border-radius: 10px;

    .separatorContainer {
      margin-bottom: 0.5em;
      padding: 0 0.5em;
    }

    .heading {
      display: flex;
      align-items: center;
      gap: 0.5em;
      padding: 0.5em;
      justify-content: space-between;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--tertiaryColor);
        padding: 0 0.5em;
        height: 100%;
        border-radius: 5px;
        transition: background 100ms ease-in-out;

        svg {
          fill: var(--primaryTextColor);
        }

        &:hover {
          background: var(--quaternaryColor);
        }
      }
    }

    .tracks {
      padding: 0 0.5em;
      height: 100%;
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: var(--accentColor) #1a1a1a;
    }

    .track {
      padding: 0.3em 0.5em;
      border-radius: 10px;

      &:not(.active) {
        cursor: pointer;
        &:hover {
          background: var(--secondaryColor);
        }
      }

      &.active {
        background: var(--tertiaryColor);
      }
    }
  }
}
