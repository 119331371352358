.match-media {
  overflow-y: auto;
  scrollbar-width: thin;
  transition: padding 300ms ease-in-out;
  transition: height 100ms ease-in-out;

  &.open-false {
    & .nested-view-container {
      overflow: hidden !important;
    }

    & .match-container {
      .match-right {
        background: #3a3a3a !important;

        .right-head {
          padding: 6px 40px;
        }
      }
      .match-head {
        padding: 20px 40px;
      }
    }
  }

  .match-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;

    & > p {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
    }

    .match-head {
      font-size: 16px;
      line-height: 16px;
      padding: 42px 0px 15px 40px;
    }

    .match-label {
      line-height: 10px;
      font-size: 12px;
      padding: 0px 5px 0px 40px;
      color: #aaa;
    }

    .match-middle {
      display: flex;
      flex-direction: row;
      align-items: center;

      .simple-searchbox {
        padding-right: 40px;
        width: 80%;
      }
    }

    @media (max-width: 1080px) {
      .match-middle {
        flex-direction: column;
      }

      .match-middle > .simple-searchbox {
        width: 100%;
        margin-left: 48px;
        > .input {
          width: 100%;
        }
      }
    }

    .match-left {
      background: #3a3a3a;
      width: 50%;
      height: 100%;
      margin: auto;
      border-radius: 20px 0 0 20px;
      position: relative;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      overflow: hidden;

      .match-folders {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: absolute;
        padding-top: 20px;
        padding-bottom: 5px;
        max-height: 18vh;

        .folders-container {
          overflow: auto;
          padding: 0 40px;
        }
      }
    }

    .match-right {
      background: #3e3e3e;
      width: 50%;
      height: 100%;
      margin: auto;
      border-radius: 0 20px 20px 0;
      display: flex;
      position: relative;
      flex-grow: 0;
      flex-shrink: 0;
      flex-direction: column;

      transition: background-color 100ms ease-in-out;

      .right-head {
        display: inline-flex;
        align-self: flex-start;
        padding: 25px 40px 5px 40px;
        overflow: hidden;
        width: 100%;
        column-gap: 30px;
        flex-shrink: 0;

        .toggle {
          display: flex;
          height: 35px;
          width: 35px;
          background: #585858;
          border-radius: 20px;
          flex-shrink: 0;
          align-self: center;
          transition: all 200ms ease-in-out;
          cursor: pointer;
          margin-left: auto;

          &:hover {
            background: #666;
          }

          svg {
            margin: auto;
            font-size: 12px;
          }

          &.invert {
            transform: rotate(180deg);
          }
        }
      }

      .right-content {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        overflow: auto;
        margin-bottom: 20px;
      }
    }
  }
}
