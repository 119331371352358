.videoPlayer {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  font-family: "Roboto Bold", Arial;
  color: var(--primaryTextColor);

  video {
    height: 100%;
    width: 100%;
    background: #000;
  }

  .overlay {
    top: 0;
    position: absolute;
    padding: 2em;
    height: 100%;
    width: 100%;
    background: linear-gradient(to top, #000, transparent 30%);
    display: grid;
    gap: 1em;
    align-items: flex-end;
    grid-template-rows: 1fr 1fr auto;
    grid-template-areas:
      "menus"
      "next_ep"
      "controls";
    transition: background 200ms ease-in-out;

    .errorBox {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: var(--secondaryColor);
      padding: 1em;
      border-radius: 10px;
      animation: appear 200ms ease-in-out;

      details {
        margin-top: 1em;

        &[open] summary ~ * {
          animation: appear 0.5s ease-in-out;
        }

        summary {
          cursor: pointer;
        }
      }

      .stderr {
        margin-top: 0.5em;
        padding: 0.5em;
        border-radius: 10px;
        background: var(--tertiaryColor);
      }

      code {
        font-family: "Roboto Regular", Arial;
        line-height: 1.5em;
      }

      .separator {
        margin: 0.5em 0;
      }

      p {
        line-height: 1.5em;
      }

      .options {
        display: grid;
        justify-content: flex-end;
        grid-template-columns: auto auto;
        gap: 1em;
      }

      button {
        margin-top: 1em;
        transition: 200ms ease-in-out;
        font-family: "Roboto Bold", Arial;
        border-radius: 10px;
        background: transparent;
        cursor: pointer;
        color: var(--primaryTextColor);
        font-size: 1em;
        padding: 0.5em 0.8em;

        &:nth-child(1) {
          transition-property: color;

          &:focus,
          &:hover {
            color: #bbb;
          }
        }

        &:nth-child(2) {
          background: var(--accentColor);
          transition-property: filter;
          filter: saturate(0.7);

          &:focus,
          &:hover {
            filter: saturate(1);
          }
        }
      }
    }
  }

  .ringLoad {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (min-width: 1080px) {
  .videoPlayer {
    .overlay {
      grid-template-rows: 1fr auto;
      grid-template-columns: 200px 1fr 200px;
      grid-template-areas: ". menus ." " . next_ep ." ". controls .";
    }
  }
}
