.barLoad {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 5px;
  transition: background 100ms ease-in-out;
  overflow: hidden;
  border-radius: 10px;

  &.small-true div {
    border: 0.2em dotted var(--accentColor);
  }

  div {
    display: block;
    position: absolute;
    width: 50%;
    height: inherit;
    background: var(--accentColor);
    border-radius: 10px;
    animation: barLoad 1500ms cubic-bezier(0.85, 0, 0.15, 1) infinite;
    border-color: var(--accentColor);
  }
}

@keyframes barLoad {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
