.card-wrapper {
  position: relative;

  &:hover {
    .card .cardImageWrapper {
      filter: brightness(1.2);

      .progress {
        opacity: 1;
      }
    }
  }

  .card {
    width: 100%;

    .cardImageWrapper {
      position: relative;
      background: var(--secondaryColor);
      transition: filter 0.2s ease-in-out;

      .imageLoad {
        animation: onHideImage 0s ease-in-out forwards;

        &.show-true {
          animation: onActiveImage 300ms ease-in-out forwards;
        }

        &.show-false {
          animation: onHideImage 300ms ease-in-out forwards;
        }
      }

      img {
        object-fit: cover;
        animation: imageLoaded 300ms ease-in;
      }

      .progress {
        position: absolute;
        background: var(--tertiaryColor);
        left: 50%;
        opacity: 0;
        bottom: 1em;
        height: 4px;
        border-radius: 10px;
        width: 90%;
        transition: opacity 200ms ease-in-out;
        transform: translateX(-50%);

        .value {
          position: absolute;
          left: 0;
          width: 0;
          max-width: 100%;
          height: 4px;
          border-radius: 4px;
          transition: width 200ms ease-in-out;
          background: var(--accentColor);
        }
      }
    }

    img,
    .cardImageWrapper,
    .placeholder {
      height: 320px;
      width: 100%;
      border-radius: 10px;
    }

    .placeholder {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 48px;
      }
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      color: var(--secondaryTextColor);
      padding: 0 2px;
      transition: opacity 0.3s ease-in-out;
      font-family: "Roboto Condensed Regular", Arial;
      margin-top: 1em;
    }

    a {
      outline: none;
    }
  }
}

/*
    * == KEYFRAMES ==
*/

@keyframes imageLoaded {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes cardHighlight {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes onActiveImage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes onHideImage {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
