:root {
  --blackBarHeight: 100px;
}

::cue {
  background: transparent;
}

.videoSubtitles {
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--blackBarHeight);
  width: 100%;
  left: 50%;
  font-size: 2em;
  transform: translate(-50%);
  position: absolute;
  text-align: center;
  opacity: 0;
  max-width: 30ch;
  transition: opacity 75ms ease-in-out;
  margin-bottom: 2.5em;
  flex-direction: column;

  p {
    text-shadow: 2px 2px 4px #000;
    font-family: "Roboto Regular", Arial;
    color: #fff;
  }

  &.show-true {
    opacity: 1;
  }
}

.JASSUB {
  position: absolute;
  left: 0;
}
