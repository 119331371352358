.profileImage {
  height: 32px;
  width: 32px;

  img,
  .placeholder {
    height: inherit;
    width: inherit;
    object-fit: cover;
    border-radius: 50%;
    background: var(--accentColor);
    animation: onShowProfileImage 0.3s ease-in-out;
  }

  &.show-true {
    animation: onShowProfileImage 0.3s ease-in-out forwards;
  }

  &.show-false {
    animation: onHideProfileImage 0.3s ease-in-out forwards;
  }
}

@keyframes onShowProfileImage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes onHideProfileImage {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
